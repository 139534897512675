<template>
    <div class="saleTable">
        <el-table
            :data="regionData"
            ref="setReg"
            style="width: 100%"
            highlight-current-row
            @row-click="selectRegion"
        >
            <el-table-column
                label="销售区域"
                prop="area_name"
            >
            </el-table-column>
            <el-table-column
                label="C30统一售价（元）"
            >
                <template slot-scope="scope">
                    <input
                        type="text"
                        :disabled="newIsRelease != 0 ? true : false"
                        v-model="scope.row.price_c30"
                        @change="preserveRegion(scope.row)"
                    >
                </template>
            </el-table-column>
            <el-table-column label="C30最低限价（元）">
                <template slot-scope="scope">
                    <input
                        type="text"
                        :disabled="newIsRelease != 0 ? true : false"
                        v-model="scope.row.price_c30_min"
                        @change="preserveRegion(scope.row)"
                    >
                </template>
            </el-table-column>
            <el-table-column label="M5统一售价（元）">
                <template slot-scope="scope">
                    <input
                        type="text"
                        :disabled="newIsRelease != 0 ? true : false"
                        v-model="scope.row.price_M5"
                        @change="preserveRegion(scope.row)"
                    >
                </template>
            </el-table-column>
            <el-table-column label="M5最低限价（元）">
                <template slot-scope="scope">
                    <input
                        type="text"
                        :disabled="newIsRelease != 0 ? true : false"
                        v-model="scope.row.price_M5_min"
                        @change="preserveRegion(scope.row)"
                    >
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    // 按区域设定 table
    name: 'region',
    components: {},
    props: {
        isRelease: {
            type: [Boolean, Number, String ],
        },
        isRegion: {
            type: Boolean,
        },
        regionData: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            activeRegion: null,
            newIsRelease: 0,
        };
    },
    watch: {
        isRelease(newVal) {
            this.newIsRelease = newVal;
        },
        isRegion() {
            setTimeout(() => {
                this.$refs.setReg.setCurrentRow(this.regionData[0]);
            }, 500);
        },
    },
    computed: {},
    methods: {
        // 选择区域
        selectRegion(row) {
            this.$emit('selectionAreaCallback', row.area_id, row);
        },
        // 区域修改保存
        preserveRegion(data) {
            this.$axios
                .put('/interfaceApi/sale//price_config/modify_price_area', data)
                .then(res => {
                    if (res) {
                        this.$message({
                            message: res,
                            type: 'success',
                        });
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        this.newIsRelease = this.isRelease;
    },
    mounted() {
        setTimeout(() => {
            this.$refs.setReg.setCurrentRow(this.regionData[0]);
        }, 500);
    },
};
</script>
<style lang="stylus" scoped>
.saleTable
    table
        width 100% !important
</style>